import { INCORRECT, UNGUESSED, PRESENT, CORRECT } from 'const';

/**
 * Formats the the given milliseconds as hours:minutes:seconds or minutes:seconds.
 * @param {number} ms Time to convert, in milliseconds.
 * @return {string} The beautifully formatted time.
 */
export function formatTime(ms: number) {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

  // Note we are also formatting numbers with a leading zero so they are two digits.
  const time =
    hours > 0
      ? `${hours < 10 ? '0' + hours : hours}:${
          minutes < 10 ? '0' + minutes : minutes
        }:${seconds < 10 ? '0' + seconds : seconds}`
      : `${minutes < 10 ? '0' + minutes : minutes}:${
          seconds < 10 ? '0' + seconds : seconds
        }`;

  return time;
}

/**
 * Returns a classname for the given hint const.
 * @param {number} hint The static const value to fetch.
 * @return {string} The relevant class.
 */
export function getTileStatusClass(
  hint: typeof INCORRECT | typeof UNGUESSED | typeof PRESENT | typeof CORRECT
) {
  switch (hint) {
    case INCORRECT:
      return 'incorrect';

    case PRESENT:
      return 'present';

    case CORRECT:
      return 'correct';

    default:
      return '';
  }
}
