import React, { useEffect, useState } from 'react';

import { GAME_WIN, GAME_FAIL } from 'const';
import { useStore } from 'store/store';
import './Ribbon.scss';

interface RibbonProps {
  line: number;
}

/**
 * Small non-interactive modal with a short message.
 */
function Ribbon(props: RibbonProps) {
  const [visible, setVisible] = useState(false);
  const state = useStore()[0];

  // Phrase map that's based on number of guesses.
  const phrases = [
    'Whoa! (Did you cheat?)',
    'Wow!',
    "Third time's a charm!",
    'Great job.',
    'Nice.',
    'That was close!',
    'Better luck next time.',
  ];

  // Once the game state changes to win or fail, show the
  // modal for a beat, then deactivate it.
  useEffect(() => {
    if (state.status === GAME_WIN || state.status === GAME_FAIL) {
      setVisible(true);

      setTimeout(() => {
        setVisible(false);
      }, 2000);
    }
  }, [state.status]);

  return (
    <div className={`ribbon ${visible ? 'is-active' : ''}`}>
      <div className="inner">{phrases[props.line - 1]}</div>
    </div>
  );
}

export default Ribbon;
