import React from 'react';

import './Heading.scss';

/**
 * Outputs the main game title element in the header area.
 */
function Heading() {
  return <div className="heading">CLUEREKA!</div>;
}

export default Heading;
