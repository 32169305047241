import React from 'react';
import CumminsLogoIcon from 'icons/CumminsLogoIcon';

import './Logo.scss';

interface LogoProps {
  reverse: boolean;
}

/**
 * Outputs the Cummins logo that links up to their website.
 * rel="noreferrer" here because the React build process suggests having
 * target="_blank" but not the noreferrer is a security risk.
 */
function Logo(props: LogoProps) {
  return (
    <div className={`logo ${props.reverse ? 'alt' : ''}`}>
      <a href="https://www.cummins.com/" target="_blank" rel="noreferrer">
        <CumminsLogoIcon iconTitle="header-logo" />
      </a>
    </div>
  );
}

export default Logo;
