import React, { useState } from 'react';

import ShareNodesIcon from 'icons/ShareNodesIcon';
import './ShareButton.scss';

interface ShareButtonProps {
  onClick: () => void;
}

/**
 * Outputs the stylized share button, executing the passed function when clicked.
 */
function ShareButton(props: ShareButtonProps) {
  const [showRibbon, setShowRibbon] = useState(false);

  /**
   * Execute the props function and show the confirmation ribbon for a couple seconds.
   */
  function handleClick() {
    props.onClick();

    setShowRibbon(true);
    setTimeout(() => {
      setShowRibbon(false);
    }, 2000);
  }

  return (
    <div
      className={`share-button ${showRibbon ? 'is-active' : ''}`}
      onClick={handleClick}
    >
      SHARE
      <ShareNodesIcon iconTitle="share-nodes" />
    </div>
  );
}

export default ShareButton;
