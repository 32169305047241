import React from 'react';

import useKeyDown from 'hooks/keyDown';
import { useStore } from 'store/store';
import Key from 'components/key/Key';
import KeyBack from 'components/key/KeyBack';
import KeyEnter from 'components/key/KeyEnter';
import './Keyboard.scss';

interface KeyboardProps {
  submit: () => void;
}

/**
 * Parent component for outputting all the keys.
 */
function Keyboard(props: KeyboardProps) {
  const dispatch = useStore()[1];

  // Handles key events for using a keyboard to type letters.
  useKeyDown(
    (key) => {
      props.submit();
    },
    ['Enter']
  );
  useKeyDown(
    (key) => {
      dispatch('REMOVE_LETTER');
    },
    ['Backspace']
  );
  useKeyDown(
    (key) => {
      dispatch('ADD_LETTER', key);
    },
    [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ]
  );

  /**
   * User has clicked or typed the back button, so take action!
   */
  function handleBack() {
    dispatch('REMOVE_LETTER');
  }

  /**
   * User has clicked or typed a letter key, so take action!
   */
  function handleLetter(letter: string) {
    dispatch('ADD_LETTER', letter);
  }

  return (
    <div className="keyboard">
      <div>
        <Key value="q" onClick={handleLetter} />
        <Key value="w" onClick={handleLetter} />
        <Key value="e" onClick={handleLetter} />
        <Key value="r" onClick={handleLetter} />
        <Key value="t" onClick={handleLetter} />
        <Key value="y" onClick={handleLetter} />
        <Key value="u" onClick={handleLetter} />
        <Key value="i" onClick={handleLetter} />
        <Key value="o" onClick={handleLetter} />
        <Key value="p" onClick={handleLetter} />
      </div>
      <div>
        <div className="spacer" />
        <Key value="a" onClick={handleLetter} />
        <Key value="s" onClick={handleLetter} />
        <Key value="d" onClick={handleLetter} />
        <Key value="f" onClick={handleLetter} />
        <Key value="g" onClick={handleLetter} />
        <Key value="h" onClick={handleLetter} />
        <Key value="j" onClick={handleLetter} />
        <Key value="k" onClick={handleLetter} />
        <Key value="l" onClick={handleLetter} />
        <div className="spacer" />
      </div>
      <div>
        <KeyEnter onClick={props.submit} />
        <Key value="z" onClick={handleLetter} />
        <Key value="x" onClick={handleLetter} />
        <Key value="c" onClick={handleLetter} />
        <Key value="v" onClick={handleLetter} />
        <Key value="b" onClick={handleLetter} />
        <Key value="n" onClick={handleLetter} />
        <Key value="m" onClick={handleLetter} />
        <KeyBack onClick={handleBack} />
      </div>
    </div>
  );
}

export default Keyboard;
