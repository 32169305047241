import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import Game from 'features/game/Game';
import Header from 'features/header/Header';
import Instructions from 'features/instructions/Instructions';
import Legal from 'features/legal/Legal';
import Stats from 'features/stats/Stats';
import reportWebVitals from './reportWebVitals';

import configureGameStore from 'store/gameStore';
import configureSettingsStore from 'store/settingsStore';
import configureStatsStore from 'store/statsStore';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || ''
};

TagManager.initialize(tagManagerArgs);

configureGameStore();
configureSettingsStore();
configureStatsStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div className="app-wrap">
      <Header />
      <Game />
      <Legal />
      <Instructions />
      <Stats />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
