/**
 * Persistent storage implementation. Currently using localStorage.
 * This is managed via namespaced objects in localStorage containing the
 * state for the namespace.
 */
const Storage = {
  // Get the full namespaced object from storage.
  get(name: string) {
    return JSON.parse(localStorage.getItem(name) || '');
  },

  // Get a single value from the namespace.
  getValue(name: string, key: string) {
    const s = JSON.parse(localStorage.getItem(name) || '');
    return s[key];
  },

  // Set a single value in the namespace.
  set(name: string, value: any) {
    if (this.has(name)) {
      const s = JSON.parse(localStorage.getItem(name) || '');
      localStorage.setItem(
        name,
        JSON.stringify({
          ...s,
          ...value,
        })
      );
    } else {
      localStorage.setItem(name, JSON.stringify(value));
    }
  },

  // Determine if the namespace is in storage at all.
  has(name: string) {
    return localStorage.getItem(name) !== null;
  },

  // Determine if the given key exists in the namespace.
  hasValue(name: string, key: string) {
    if (localStorage.getItem(name) === null) return false;

    const s = JSON.parse(localStorage.getItem(name) || '');
    return s[key] !== null;
  },
};

export default Storage;
