import React from 'react';

import './Tile.scss';

interface TileProps {
  letter: string;
  status: string;
}

/**
 * Output the given letter with the status modifier stylized as a tile within a Guess.
 */
function Tile(props: TileProps) {
  return (
    <div className={`tile ${props.status}`}>
      <div className="inner">
        <div className="front">{props.letter}</div>
        <div className={`back ${props.status}`}>{props.letter}</div>
      </div>
    </div>
  );
}

export default Tile;
