import React from 'react';

interface SquareQuestionProps {
  iconTitle: string;
}

const SquareQuestionIcon = (props: SquareQuestionProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Square Question Icon</title>
      <path d="M253.6 128H194.4C157.8 128 128 157.8 128 194.4V204c0 8.844 7.156 16 16 16S160 212.8 160 204V194.4C160 175.4 175.4 160 194.4 160h59.25C272.6 160 288 175.4 288 194.4c0 12.48-6.781 24-17.06 29.72L210.5 254.3C199.1 260.7 192 272.7 192 285.8V304C192 312.8 199.2 320 208 320S224 312.8 224 304V285.8c0-1.453 .7813-2.797 1.438-3.172l60.41-30.22C306.9 240.7 320 218.5 320 194.4C320 157.8 290.2 128 253.6 128zM208 344c-13.25 0-24 10.75-24 24s10.75 24 24 24s24-10.75 24-24S221.3 344 208 344zM384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM416 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32V416z" />
    </svg>
  );
};

export default SquareQuestionIcon;
