import React from 'react';

import DeleteLeftIcon from 'icons/DeleteLeftIcon';

interface KeyBackProps {
  onClick: () => void;
}

/**
 * The backspace key. When clicked, removes one letter
 * from the end of the active guess.
 */
function KeyBack(props: KeyBackProps) {
  return (
    <div className="key back alt" onClick={props.onClick}>
      <DeleteLeftIcon iconTitle="delete" />
    </div>
  );
}

export default KeyBack;
