import React from 'react';

import { formatTime } from 'utility';
import { useStore } from 'store/store';
import ShareButton from 'components/buttons/share/ShareButton';

interface ShareProps {
  type: 'gametime' | 'stats';
}

/**
 * Container component to manage how "sharing" the game will work.
 * Right now, we are just putting copy into the clipboard, depending on the location
 * of the share button (ie. "type");
 */
function Share(props: ShareProps) {
  const state = useStore()[0];

  /**
   * Put the relevant copy into the clipboard.
   */
  function share() {
    let copy = '';

    switch (props.type) {
      case 'gametime':
        copy = `I solved CLUEREKA! in ${formatTime(
          state.game_time
        )}. Give it a try at https://cluereka.wegetb2b.life/`;
        break;

      case 'stats':
        copy = `Games Played: ${
          state.games_played
        }\nWin Percentage: ${Math.round(
          (100 * state.games_won) / state.games_played
        )}\nAverage Time: ${formatTime(
          state.average_time
        )}\nCheck it out here https://cluereka.wegetb2b.life/`;
    }

    navigator.clipboard.writeText(copy);
  }

  return <ShareButton onClick={share} />;
}

export default Share;
