import React, { useEffect, useState } from 'react';

import { formatTime } from 'utility';
import './NextPuzzle.scss';

interface NextPuzzleProps {
  running: boolean;
}

/**
 * Presentational component that manages its own state in order to provide a countdown
 * to the next puzzle, which is always 12am Eastern time.
 * Strictly speaking, this should be based on the time on the server hosting this
 * application. So we will want to make sure it is set to Eastern time.
 */
function NextPuzzle(props: NextPuzzleProps) {
  const [timer, setTimer] = useState(0);
  // These three lines effectively result in the timestamp that the next puzzle starts.
  const m = new Date();
  m.setHours(24, 0, 0, 0);
  const midnight = m.getTime();

  // Set up the timer and kick off the interval to begin the countdown.
  // Note for performance reasons, the interval can be turned on and off based on the prop.
  // Basically, we don't want to run the interval unless the component is visible.
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    // Determines time remaining in milliseconds.
    setTimer(midnight - Date.now());

    if (props.running) {
      interval = setInterval(() => {
        // setInterval is not guaranteed to execute on time, so as long as we're
        // recalculating the time remaining each tick, and not simply ticking down a timer,
        // it can remain fairly accurate and self-adjust for setInterval drift.
        setTimer(midnight - Date.now());
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [props.running, midnight, setTimer]);

  return (
    <div className="next-puzzle">
      NEXT CLUEREKA!
      <span> {formatTime(timer)}</span>
    </div>
  );
}

export default NextPuzzle;
