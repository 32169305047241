import { GAME_LIVE, GAME_WIN, GAME_FAIL } from 'const';
import { initStore } from './store';
import { formatTime } from 'utility';
import { Post } from 'api';
import Storage from 'storage';

/**
 * Creates the store for managing game loop state.
 */
const configureGameStore = () => {
  const actions = {
    // Sets the fetching state.
    SET_FETCHING: (state: any, fetching: boolean) => ({
      is_fetching: fetching,
    }),

    // Updates the current game status.
    GAME_STATUS: (
      state: any,
      status: typeof GAME_LIVE | typeof GAME_WIN | typeof GAME_FAIL
    ) => {
      if (status === GAME_WIN || status === GAME_FAIL) {
        Post('completion', {
          win: status === GAME_WIN ? 1 : 0,
          game_time: formatTime(state.game_time),
          game_lines: state.guesses.length,
          games_played: state.games_played,
        });
      }

      Storage.set('cluereka', { status });
      return { status: status };
    },

    // Adds a letter to the end of the active letters.
    ADD_LETTER: (state: any, letter: string) => {
      if (state.letters.length < 8 && state.status === GAME_LIVE)
        return { letters: state.letters.concat(letter) };
    },

    // Removes a letter from the end of the active letters.
    REMOVE_LETTER: (state: any) => ({
      letters: state.letters.slice(0, state.letters.length - 1),
    }),

    // Adds a guess to the guess array, resetting the active letters.
    ADD_GUESS: (state: any, guess: any) => {
      if (state.status === GAME_LIVE) {
        Storage.set('cluereka', { guesses: state.guesses.concat([guess]) });
        return { guesses: state.guesses.concat([guess]), letters: [] };
      }
    },

    // Add a clue to the clue array.
    ADD_CLUE: (state: any, clue: string) => {
      Storage.set('cluereka', { clues: state.clues.concat(clue) });
      return { clues: state.clues.concat(clue) };
    },

    // Merge the given hints with the existing hints, keeping the highest value for each
    // letter. This is effectively the keyboard hint status.
    ADD_HINTS: (state: any, hints: any) => {
      const merge: any = {};

      Object.keys(hints).forEach((key: string) => {
        if (!state.hints[key]) merge[key] = hints[key];
        else if (hints[key] > state.hints[key]) merge[key] = hints[key];
      });

      Storage.set('cluereka', { hints: { ...state.hints, ...merge } });
      return { hints: { ...state.hints, ...merge } };
    },

    ADD_GAME_TIME: (state: any) => {
      const gameTime = Date.now() - state.start_time;

      Storage.set('cluereka', {
        game_time: state.game_time + gameTime,
        start_time: 0,
      });
      return { game_time: state.game_time + gameTime, start_time: 0 };
    },

    START_GAME_TIME: (state: any) => {
      const startTime = Date.now();

      Storage.set('cluereka', { start_time: startTime });
      return { start_time: startTime };
    },

    // Add a solution to the game state. Intentionally not stored in the persistent storage
    // as an attempt to reduce cheating vectors.
    ADD_SOLUTION: (state: any, solution: string) => ({ solution: solution }),

    // Add the copy for postgame modal to the game state. Intentionally not stored in the
    // persistent storage as an attempt to reduce cheating vectors.
    ADD_POSTGAME: (state: any, postgame: string) => ({ postgame: postgame }),

    // Initialize the game board state to the initial state for a new puzzle.
    INIT_BOARD: (state: any, newState: any) => {
      Storage.set('cluereka', {
        clues: newState.clues,
        game_time: 0,
        guesses: [],
        hints: {},
        puzzle_date: newState.puzzle_date,
        puzzle_id: newState.puzzle_id,
        start_time: newState.start_time,
        status: GAME_LIVE,
      });
      return { ...state, ...newState };
    },

    // Load the game board state from the persistent storage.
    LOAD_BOARD: (state: any) => {
      const s = Storage.get('cluereka');

      return {
        ...state,
        clues: s.clues,
        game_time: s.game_time,
        guesses: s.guesses,
        hints: s.hints,
        puzzle_date: s.puzzle_date,
        puzzle_id: s.puzzle_id,
        start_time: s.start_time,
        status: s.status,
        viewed_instructions: s.viewed_instructions,
      };
    },
  };

  initStore(actions, {
    clues: [],
    game_time: 0,
    guesses: [],
    hints: {},
    is_fetcing: false,
    letters: [],
    postgame: '',
    puzzle_date: '',
    puzzle_id: -1,
    show_instructions: false,
    show_stats: false,
    solution: '',
    start_time: '',
    status: GAME_LIVE,
    viewed_instructions: false,
  });
};

export default configureGameStore;
