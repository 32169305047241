import React from 'react';

import './Hamburger.scss';

interface HamburgerProps {
  reverse: boolean;
  toggleMenu: () => void;
}

/**
 * Hamburger menu allowing the user to click open the settings menu.
 */
function Hamburger(props: HamburgerProps) {
  return (
    <div
      className={`hamburger ${props.reverse ? 'alt' : ''}`}
      onClick={props.toggleMenu}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Hamburger;
