import React from 'react';

import Tile from 'components/tile/Tile';
import LightbulbIcon from 'icons/LightbulbIcon';

interface ActiveGuessProps {
  letters: string[];
  clue: string;
}

/**
 * Clue and guess line for the user's current guess.
 */
function ActiveGuess(props: ActiveGuessProps) {
  return (
    <div className="guess">
      <div className="clue">
        <div className="guideline"></div>
        <LightbulbIcon iconTitle="lightbulb" />
        <div className="copy">{props.clue}</div>
      </div>
      <div className="line">
        {props.letters.map((item: string) => (
          <Tile letter={item} status="unsolved" />
        ))}

        {props.letters.length < 8 ? <div className="cursor"></div> : ''}
      </div>
    </div>
  );
}

export default ActiveGuess;
