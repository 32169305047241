import axios from 'axios';

// execute a POST request via axios
function request(data: any) {
  const url = process.env.REACT_APP_API_URL || '';

  return axios({
    url: url,
    method: 'POST',
    data: data,
  })
    .then((response) => {
      if (response.status === 200) {
        try {
          response.data.data = JSON.parse(response.data.data);
        } catch (e) {
          console.error(e);
        }

        return response.data;
      } else {
        if (response.status === 500) {
          console.error(response.data.data.message);
          return response.data;
        }
      }
    })
    .catch((error: any) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
}

// provide a Get function for executing API requests
export const Get = (endpoint: string) => {
  return request({ endpoint: endpoint });
};

// provide a Post function for executing API requests
// that will add new data to the database.
// data here should be a json object.
export const Post = (endpoint: string, data: any) => {
  data.endpoint = endpoint;
  data.post = true;
  return request(data);
};
