import React from 'react';
import { GAME_LIVE } from 'const';

import { formatTime } from 'utility';
import { useStore } from 'store/store';
import './TimeSolved.scss';

/**
 * Outputs the time to complete of the current puzzle with some copy.
 */
function TimeSolved() {
  const state = useStore()[0];

  // Output a message based on the player's game time.
  function message() {
    if (state.game_time <= 60000) {
      return 'Easy there — you’re so fast you nearly fried my circuits!';
    } else if (state.game_time <= 180000) {
      return 'Nicely done. Maybe you can boost your “cycle time” tomorrow?';
    } else if (state.game_time <= 600000) {
      return 'I admire your slow and methodical approach.';
    } else {
      return 'Huh? Are you still there? Sorry, I went into sleep mode.';
    }
  }

  return (
    <div className="time-solved">
      {state.status === GAME_LIVE ? (
        <div className="message">Don't forget to play today's CLUEREKA!</div>
      ) : (
        <div className="message">
          {message()}
          <br />
          You completed today's CLUEREKA! in
        </div>
      )}
      {state.status === GAME_LIVE ? (
        ''
      ) : (
        <div className="time">{formatTime(state.game_time)}</div>
      )}
    </div>
  );
}

export default TimeSolved;
