import { useCallback, useEffect, useState } from 'react';

/**
 * Custom hook for handling keydown and keyup events based on
 * which key was pressed.
 *
 * Usage
 * useKeyDown((key) => {
 *   doSomethingWhenOneOfTheseKeysIsPressed();
 * }, ['w', 'a', 's', 'd']);
 */
const useKeyDown = (callback: (key: string) => void, keys: string[]) => {
  // Pressed here is used to prevent duplicate keypresses when
  // holding down a key.
  const [pressed, setPressed] = useState(false);

  // Determine if the given key was pressed and if so,
  // prevent the default action, updated pressed state, and
  // execute the given function.
  const handleKeyDown = useCallback(
    (event: any) => {
      if (!pressed) {
        const wasAnyKeyPressed = keys.some((key) => event.key === key);

        if (wasAnyKeyPressed) {
          event.preventDefault();
          setPressed(true);
          callback(event.key);
        }
      }
    },
    [callback, keys, pressed]
  );

  // When we catch a keyup event, simply inform the pressed
  // state we can access keypresses again.
  const handleKeyUp = useCallback(() => {
    setPressed(false);
  }, []);

  // Attach and detach event handlers on the window.
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);
};

export default useKeyDown;
