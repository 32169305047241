import React from 'react';

interface RectangleXMarkProps {
  iconTitle: string;
}

const RectangleXMarkIcon = (props: RectangleXMarkProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Rectangle X Mark Icon</title>
      <path d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM0 96C0 60.65 28.65 32 64 32H448C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H448C465.7 448 480 433.7 480 416V96C480 78.33 465.7 64 448 64H64C46.33 64 32 78.33 32 96z" />
    </svg>
  );
};

export default RectangleXMarkIcon;
