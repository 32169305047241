import React from 'react';

import { formatTime } from 'utility';
import { useStore } from 'store/store';
import Share from 'features/share/Share';
import ModalScreen from 'components/modalScreen/ModalScreen';
import NextPuzzle from 'components/nextPuzzle/NextPuzzle';
import Statline from 'components/statline/Statline';
import TimeSolved from 'components/timeSolved/TimeSolved';
import './Stats.scss';

/**
 * The stats screen that display's the players overall stats.
 */
function Stats() {
  const [state, dispatch] = useStore();

  /**
   * User clicked the close button so let's hide this screen.
   */
  function close() {
    dispatch('TOGGLE_STATS');
  }

  return (
    <ModalScreen
      heading="Your CLUEREKA! Stats"
      close={close}
      visible={state.show_stats}
    >
      <div className="stats">
        <TimeSolved />
        <div className="statlines">
          <Statline stat="Games Played" value={state.games_played} />
          <Statline
            stat="Win Percentage"
            value={
              Math.round((100 * state.games_won) / state.games_played) || 0
            }
          />
          <Statline
            stat="Average Time"
            value={formatTime(state.average_time)}
          />
        </div>
        <Share type="stats" />
        <NextPuzzle running={state.show_stats} />
      </div>
    </ModalScreen>
  );
}

export default Stats;
