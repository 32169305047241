import React from 'react';

import { useStore } from 'store/store';
import Alert from 'components/alert/Alert';
import './Legal.scss';

/**
 * The legal notice alert modal. Has some legal mumbo jumbo for all users.
 */
function Legal() {
  const [state, dispatch] = useStore();

  /**
   * User clicked the close button so let's hide this thing.
   */
  function close() {
    dispatch('TOGGLE_LEGAL');
  }

  return (
    <Alert heading="Notice" close={close} visible={state.show_legal}>
      <div className="legal">
        <p>
          Please be advised that at present, Cluereka is undergoing testing
          solely among individuals employed by Cummins and residing in North
          America. It is hereby requested that the aforementioned link not be
          disclosed or shared with individuals located outside of North America.
        </p>
      </div>
    </Alert>
  );
}

export default Legal;
