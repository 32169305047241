import React from 'react';

import './Statline.scss';

interface StatlineProps {
  stat: string;
  value: string | number;
}

/**
 * Simply present the given stat and value.
 */
function Statline(props: StatlineProps) {
  return (
    <div className="statline">
      <div className="stat">{props.stat}</div>
      <div className="value">{props.value}</div>
    </div>
  );
}

export default Statline;
