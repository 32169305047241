import React from 'react';

interface LightbulbProps {
  iconTitle: string;
}

const LightbulbIcon = (props: LightbulbProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.39 85.99"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Lightbulb Icon</title>
      <g>
        <path d="m11.18,35.24H3.36c-1.85,0-3.36-1.5-3.36-3.36s1.5-3.36,3.36-3.36h7.82c1.85,0,3.36,1.5,3.36,3.36s-1.5,3.36-3.36,3.36Z" />
        <path d="m15.32,15.49c-.42,0-.85-.08-1.27-.25l-7.24-2.96c-1.72-.7-2.54-2.66-1.84-4.38.7-1.72,2.67-2.55,4.38-1.84l7.24,2.96c1.72.7,2.54,2.66,1.84,4.38-.53,1.3-1.79,2.09-3.11,2.09Z" />
        <path d="m8.08,57.95c-1.32,0-2.58-.79-3.11-2.09-.7-1.71.12-3.68,1.84-4.38l7.24-2.96c1.71-.7,3.67.12,4.38,1.84.7,1.71-.12,3.68-1.84,4.38l-7.24,2.96c-.42.17-.84.25-1.27.25Z" />
      </g>
      <g>
        <path d="m113.03,35.24h-7.82c-1.85,0-3.36-1.5-3.36-3.36s1.5-3.36,3.36-3.36h7.82c1.85,0,3.36,1.5,3.36,3.36s-1.5,3.36-3.36,3.36Z" />
        <path d="m101.06,15.49c-1.32,0-2.58-.79-3.11-2.09-.7-1.71.12-3.68,1.84-4.38l7.24-2.96c1.71-.7,3.68.12,4.38,1.84.7,1.71-.12,3.68-1.84,4.38l-7.24,2.96c-.42.17-.84.25-1.27.25Z" />
        <path d="m108.3,57.95c-.42,0-.85-.08-1.27-.25l-7.24-2.96c-1.72-.7-2.54-2.66-1.84-4.38.7-1.72,2.66-2.55,4.38-1.84l7.24,2.96c1.72.7,2.54,2.66,1.84,4.38-.53,1.3-1.79,2.09-3.11,2.09Z" />
      </g>
      <path d="m76.66,85.99h-36.94c-1.85,0-3.36-1.5-3.36-3.36s1.5-3.36,3.36-3.36h36.94c1.85,0,3.36,1.5,3.36,3.36s-1.5,3.36-3.36,3.36Z" />
      <path d="m74.99,73.24h-37.29l.35-3.69c0-.1.82-9.55-5.99-15.36-11.53-9.85-10.65-30.24-2.16-41.9C38.94-.11,57.32-.01,58.24,0c.81.03,19.21-.1,28.24,12.29h0c8.49,11.66,9.37,32.06-2.16,41.9-6.81,5.81-6,15.26-5.99,15.36l.35,3.68h-3.69Zm-30.25-6.72h26.9c.31-4.43,1.86-11.93,8.31-17.44,8.54-7.29,7.58-23.92,1.09-32.84h0c-6.96-9.57-22.54-9.48-22.81-9.52-.31-.08-15.9-.08-22.89,9.52-6.49,8.91-7.45,25.54,1.09,32.84,6.45,5.51,8,13.01,8.31,17.44Z" />
    </svg>
  );
};

export default LightbulbIcon;
