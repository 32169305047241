import React, { PropsWithChildren } from 'react';

import './Setting.scss';

interface SettingProps {
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

/**
 * Outputs a stylized setting line, utilizing the child elements.
 * You can pass (as a child element) a <div className="widget-onoff" /> to create
 * an on-off boolean widget setting.
 */
function Setting(props: PropsWithChildren<SettingProps>) {
  return (
    <div
      className={`setting ${props.active ? 'is-active' : ''} ${
        props.disabled ? 'is-disabled' : ''
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export default Setting;
