import React from 'react';

import ChartLineUpIcon from 'icons/ChartLineUpIcon';
import Setting from 'components/setting/Setting';
import SquareQuestionIcon from 'icons/SquareQuestionIcon';
import './Menu.scss';

interface MenuProps {
  showInstructions: () => void;
  showStats: () => void;
  toggleContrast: () => void;
  visible: boolean;
}

/**
 * Outputs the menu with the various options and whatnot.
 */
function Menu(props: MenuProps) {
  return (
    <div className={`menu ${props.visible ? 'is-active' : ''}`}>
      <Setting onClick={props.showInstructions}>
        <div>How to play CLUEREKA!</div>
        <SquareQuestionIcon iconTitle="instructions" />
      </Setting>
      <Setting onClick={props.showStats}>
        <div>Your CLUEREKA! Stats</div>
        <ChartLineUpIcon iconTitle="player stats" />
      </Setting>
      <Setting onClick={() => {}}>
        <div>Feedback</div>
        <a href="mailto:akshada.ananthakrishnan@cummins.com">Email</a>
      </Setting>
      <small className="copyright">© 2023 Cummins Inc.</small>
    </div>
  );
}

export default Menu;
