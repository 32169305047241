import React, { PropsWithChildren } from 'react';

import CloseButton from 'components/buttons/close/CloseButton';
import './ModalScreen.scss';

interface ModalScreenProps {
  close: () => void;
  heading: string;
  visible: boolean;
}

/**
 * Wrapper component for the reusable modal screen styling.
 * Note we output the core elements here, then just output the given child elements.
 */
function ModalScreen(props: PropsWithChildren<ModalScreenProps>) {
  return (
    <div className={`modal-screen ${props.visible ? 'is-active' : ''}`}>
      <CloseButton onClick={props.close} />
      <div className="panel-heading">{props.heading}</div>
      {props.children}
    </div>
  );
}

export default ModalScreen;
