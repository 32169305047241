import { initStore } from './store';
import Storage from 'storage';

/**
 * Creates the store for managing player stats state.
 */
const configureStatsStore = () => {
  const actions = {
    // Bump the player's games played value by 1.
    ADD_GAME_PLAYED: (state: any) => {
      const newGamesPlayed = state.games_played + 1;

      Storage.set('stats', { games_played: newGamesPlayed });
      return { games_played: newGamesPlayed };
    },

    // Bump the player's games won value by 1.
    ADD_GAME_WON: (state: any) => {
      const newGamesWon = state.games_won + 1;

      Storage.set('stats', { games_won: newGamesWon });
      return { games_won: newGamesWon };
    },

    // Recalculate the player's average game time (in ms) using a formula for
    // tracking a rolling average.
    SET_AVERAGE_TIME: (state: any) => {
      const newAverage =
        ((state.games_played - 1) / state.games_played) * state.average_time +
        (1 / state.games_played) * state.game_time;

      Storage.set('stats', { average_time: newAverage });
      return { average_time: newAverage };
    },

    // Set up the player stats with an initial state.
    INIT_STATS: (state: any) => {
      Storage.set('stats', {
        games_played: 0,
        games_won: 0,
        average_time: 0,
      });
      return { ...state };
    },

    // Load the player stats state from the persistent storage.
    LOAD_STATS: (state: any) => {
      const s = Storage.get('stats');

      return {
        ...state,
        games_played: s.games_played,
        games_won: s.games_won,
        average_time: s.average_time,
      };
    },
  };

  initStore(actions, {
    games_played: 0,
    games_won: 0,
    average_time: 0,
  });
};

export default configureStatsStore;
