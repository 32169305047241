import React, { PropsWithChildren } from 'react';

import CloseButton from 'components/buttons/close/CloseButton';
import './Alert.scss';

interface AlertProps {
  close: () => void;
  heading: string;
  visible: boolean;
}

/**
 * Smaller modal window wrapper that acts as an alert message.
 */
function Alert(props: PropsWithChildren<AlertProps>) {
  return (
    <div className={`alert ${props.visible ? 'is-active' : ''}`}>
      <div className="inner">
        <CloseButton onClick={props.close} />
        <div className="panel-heading">{props.heading}</div>
        {props.children}
      </div>
    </div>
  );
}

export default Alert;
