import React from 'react';

interface KeyEnterProps {
  onClick: () => void;
}

/**
 * The enter key. When clicked, fires the passed function
 * to submit the active guess.
 */
function KeyEnter(props: KeyEnterProps) {
  return (
    <div className="key enter alt" onClick={props.onClick}>
      enter
    </div>
  );
}

export default KeyEnter;
