import React from 'react';

import { useStore } from 'store/store';
import ModalScreen from 'components/modalScreen/ModalScreen';
import instructionImg from 'images/cluereka-instructions.png';
import './Instructions.scss';

/**
 * The instructions screen, telling the user the basic game rules.
 */
function Instructions() {
  const [state, dispatch] = useStore();

  /**
   * User clicked the close button so let's hide this screen.
   * Also note that the user has now seen the instructions.
   */
  function close() {
    dispatch('TOGGLE_INSTRUCTIONS');
    dispatch('VIEWED_INSTRUCTIONS');
  }

  return (
    <ModalScreen
      heading="How to play CLUEREKA!"
      close={close}
      visible={state.show_instructions}
    >
      <div className="instructions">
        <p>Guess the CLUEREKA! word of the day in 6 tries or less.</p>
        <p>
          Your guess must be 8 letters or less. Hit Enter to submit your guess.
        </p>
        <p>
          After each guess, you will get another clue. The color of the tiles
          will change colors to show how closely your guess was to the CLUEREKA!
          word.
        </p>
        <p className="wider">
          <img src={instructionImg} alt="Instructions" />
        </p>
        <p className="center">
          We welcome you to play the new <span>CLUEREKA!</span> daily.
        </p>
      </div>
    </ModalScreen>
  );
}

export default Instructions;
