import React, { useEffect, useState } from 'react';

import { GAME_WIN, GAME_FAIL } from 'const';
import { useStore } from 'store/store';
import Share from 'features/share/Share';
import CloseButton from 'components/buttons/close/CloseButton';
import NextPuzzle from 'components/nextPuzzle/NextPuzzle';
import SolvedGuess from 'components/guess/SolvedGuess';
import TimeSolved from 'components/timeSolved/TimeSolved';
import './Postgame.scss';

interface PostgameProps {
  showStats: () => void;
}

/**
 * Modal screen that appears after the user finishes the game.
 * Contains a time to solve, the solution, and some copy.
 */
function Postgame(props: PostgameProps) {
  const [visible, setVisible] = useState(false);
  const state = useStore()[0];

  // Watch for when the game status is win or fail, then wait
  // a beat for the ribbon and show this modal.
  useEffect(() => {
    if (state.status === GAME_WIN || state.status === GAME_FAIL) {
      setTimeout(() => {
        setVisible(true);
      }, 2000);
    }
  }, [state.status]);

  /**
   * Closes the modal.
   */
  function close() {
    setVisible(false);
  }

  return (
    <div className={`postgame ${visible ? 'is-active' : ''}`}>
      <CloseButton onClick={close} />
      <NextPuzzle running={visible} />
      <TimeSolved />
      <SolvedGuess solution={state.solution} />
      <div
        className="body"
        dangerouslySetInnerHTML={{ __html: state.postgame }}
      />
      <Share type="gametime" />
      <div className="stats-link">
        See all your stats <button onClick={props.showStats}>here</button>
      </div>
    </div>
  );
}

export default Postgame;
