import React from 'react';

import Tile from 'components/tile/Tile';
import LightbulbIcon from 'icons/LightbulbIcon';

interface SolvedGuessProps {
  solution: string;
}

/**
 * This outputs a stylized guess line that is intended to output the final solution.
 * It removes the clue text and all tiles are "correct" and simply uses the given
 * string for the letters.
 * Currently only used in the Postgame screen to stylize the solution there.
 */
function SolvedGuess(props: SolvedGuessProps) {
  return (
    <div className="guess solved">
      <div className="clue">
        <div className="guideline"></div>
        <LightbulbIcon iconTitle="lightbulb" />
      </div>
      <div className="line">
        {props.solution.split('').map((letter: string) => (
          <Tile letter={letter} status="solved" />
        ))}
      </div>
    </div>
  );
}

export default SolvedGuess;
