import { initStore } from './store';
import Storage from 'storage';

/**
 * Creates the store for managing game settings.
 */
const configureSettingsStore = () => {
  const actions = {
    // Enable or disable high contrast mode.
    TOGGLE_HIGH_CONTRAST: (state: any) => {
      Storage.set('settings', { high_contrast: !state.setting_high_contrast });
      return { setting_high_contrast: !state.setting_high_contrast };
    },

    // Show or hide the legal alert screen.
    TOGGLE_LEGAL: (state: any) => ({ show_legal: !state.show_legal }),

    // Show or hide the stats screen.
    TOGGLE_STATS: (state: any) => ({ show_stats: !state.show_stats }),

    // Show or hide the instructions screen.
    TOGGLE_INSTRUCTIONS: (state: any) => ({
      show_instructions: !state.show_instructions,
    }),

    // Hide the stats screen.
    HIDE_STATS: (state: any) => ({ show_stats: false }),

    // Hide the instructions screen.
    HIDE_INSTRUCTIONS: (state: any) => ({ show_instructions: false }),

    // Users has viewed the instructions so let's mark that
    // so we don't show it to them unprompted again.
    VIEWED_INSTRUCTIONS: (state: any) => {
      Storage.set('settings', { viewed_instructions: true });
      return { viewed_instructions: true };
    },

    // Set up the player settings with an initial state.
    INIT_SETTINGS: (state: any) => {
      Storage.set('settings', {
        high_contrast: false,
        viewed_instructions: false,
      });
      return { ...state };
    },

    // Load the player settings state from the persistent storage.
    LOAD_SETTINGS: (state: any) => {
      const s = Storage.get('settings');

      return {
        ...state,
        setting_high_contrast: s.high_contrast,
        viewed_instructions: s.view_instructions,
      };
    },
  };

  initStore(actions, {
    setting_high_contrast: false,
    show_instructions: false,
    show_legal: true,
    show_stats: false,
    viewed_instructions: false,
  });
};

export default configureSettingsStore;
