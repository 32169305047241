import React, { useCallback, useEffect, useState } from 'react';

import { GAME_LIVE, GAME_WIN, GAME_FAIL, GuessLetter } from 'const';
import { useStore } from 'store/store';
import { Get } from 'api';
import Storage from 'storage';
import 'styles/app.scss';

import ActiveGuess from 'components/guess/ActiveGuess';
import Guess from 'components/guess/Guess';
import Keyboard from 'features/keyboard/Keyboard';
import Postgame from 'components/postgame/Postgame';
import Ribbon from 'components/ribbon/Ribbon';
import './Game.scss';

/**
 * Core game feature, managing the game board and loop.
 */
function Game() {
  const [showActiveGuess, setShowActiveGuess] = useState(true);
  const [state, dispatch] = useStore();

  // Load existing game state, or kick off a new game.
  useEffect(() => {
    if (hasCurrentPuzzle()) {
      dispatch('LOAD_BOARD');
      dispatch('START_GAME_TIME');
    } else {
      Get('puzzle/')
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch('INIT_BOARD', {
            puzzle_id: data.puzzle_id,
            puzzle_date: data.puzzle_date,
            clues: data.clues,
            start_time: Date.now(),
          });
        })
        .catch((error) => {
          console.log('fatal fetch error');
          console.error(error);
        });
    }

    // Also load settings from storage, if they are there.
    if (Storage.has('settings')) {
      dispatch('LOAD_SETTINGS');
    } else {
      dispatch('INIT_SETTINGS');
    }

    // Also load the overall stats from storage, if they are there.
    if (Storage.has('stats')) {
      dispatch('LOAD_STATS');
    } else {
      dispatch('INIT_STATS');
    }

    // Show the instructions screen if necessary.
    if (Storage.has('settings')) {
      if (Storage.getValue('settings', 'viewed_instructions') === false) {
        dispatch('TOGGLE_INSTRUCTIONS');
      }
    } else {
      dispatch('TOGGLE_INSTRUCTIONS');
    }
  }, [dispatch]);

  // Respond to game status changes
  useEffect(() => {
    if (state.status === GAME_LIVE) return;

    Get(`solution/${state.puzzle_id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        // Sets all links in the body to open in a new tab.
        const ps = data.postgame_copy.replaceAll(
          '<a',
          '<a target="_blank" rel="noreferrer"'
        );

        dispatch('ADD_SOLUTION', data.solution);
        dispatch('ADD_POSTGAME', ps);
      })
      .catch((error) => {
        console.log('fatal fetch error');
        console.error(error);
      });
  }, [dispatch, state.status, state.puzzle_id]);

  /**
   * React to the new visibilityState, adding in game time
   * or restarting the timer.
   */
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      dispatch('ADD_GAME_TIME');
    } else {
      dispatch('START_GAME_TIME');
    }
  }, [dispatch]);

  // Toggle the event listener on tab visibility so we can
  // track game time a little more accurately.
  useEffect(() => {
    // Be sure we only run track this if the game is afoot.
    if (state.status === GAME_LIVE) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange, state.status]);

  /**
   * Query the API with the user's guess, if appropriate, when the user tries to submit their
   * active guess.
   */
  function submitGuess() {
    if (
      state.status === GAME_WIN ||
      state.status === GAME_FAIL ||
      state.letters.length === 0 ||
      state.is_fetching === true
    )
      return;

    dispatch('SET_FETCHING', true);

    const line = state.guesses.length + 1;

    Get(`puzzle/${state.puzzle_id}/${line}/${state.letters.join('')}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        const guess = [];
        const hints: any = {};
        // Delay here is a calculation of how long it takes for all the tiles in the
        // guess to flip to their hint state.
        const delay = 800 + state.letters.length * 100 - 200;

        for (let i = 0; i < state.letters.length; i++) {
          let l = state.letters[i];

          guess.push({
            letter: l,
            hint: data.hints[i],
          });

          if (!hints[l]) {
            hints[l] = data.hints[i];
          } else {
            hints[l] = data.hints[i] > hints[l] ? data.hints[i] : hints[l];
          }
        }

        dispatch('ADD_GUESS', guess);
        dispatch('SET_FETCHING', false);
        setShowActiveGuess(false);

        setTimeout(() => {
          dispatch('ADD_HINTS', hints);
          setShowActiveGuess(true);
        }, delay);

        if (data.correct) {
          dispatch('ADD_GAME_TIME');
          dispatch('ADD_GAME_PLAYED');
          dispatch('ADD_GAME_WON');

          setTimeout(() => {
            dispatch('GAME_STATUS', GAME_WIN);
            dispatch('SET_AVERAGE_TIME');
          }, delay);
        } else if (line === 6) {
          dispatch('ADD_GAME_TIME');
          dispatch('ADD_GAME_PLAYED');

          setTimeout(() => {
            dispatch('GAME_STATUS', GAME_FAIL);
          }, delay);
        } else {
          dispatch('ADD_CLUE', data.clues.at(-1));
        }
      })
      .catch((error) => {
        console.log('fatal fetch error');
        console.error(error);
      });
  }

  function showStats() {
    dispatch('TOGGLE_STATS');
  }

  /**
   * Determine if the user has already begun or completed today's puzzle.
   */
  function hasCurrentPuzzle() {
    if (!Storage.has('cluereka')) return false;

    const puzzleDate = Storage.getValue('cluereka', 'puzzle_date');
    const datetime = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
    });
    const currentPuzzleDate = new Date(datetime).toISOString().slice(0, 10);

    return puzzleDate === currentPuzzleDate;
  }

  /**
   * Iterate over the guesses in state, outputting each component.
   */
  function outputGuesses() {
    return state.guesses.map((guess: GuessLetter[], index: number) => (
      <Guess key={index} letters={guess} clue={state.clues[index]} />
    ));
  }

  return (
    <div className="gameboard">
      {state.clues ? (
        <>
          {outputGuesses()}
          {state.guesses.length < 6 &&
          state.status === GAME_LIVE &&
          showActiveGuess ? (
            <ActiveGuess letters={state.letters} clue={state.clues.at(-1)} />
          ) : (
            ''
          )}
          <Keyboard submit={submitGuess} />
          <Ribbon
            line={state.status === GAME_FAIL ? 7 : state.guesses.length}
          />
          <Postgame showStats={showStats} />
        </>
      ) : (
        <p>
          Hey there! We've hit the pause button on Cluereka for a little while
          as we take a step back and evaluate our next moves. Thanks for
          stopping by and keep an eye out for some exciting updates and
          announcements on Cluereka in the near future!
        </p>
      )}
    </div>
  );
}

export default Game;
