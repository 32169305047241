import React from 'react';

import { GuessLetter } from 'const';
import { getTileStatusClass } from 'utility';
import Tile from 'components/tile/Tile';
import LightbulbIcon from 'icons/LightbulbIcon';
import './Guess.scss';

interface GuessProps {
  letters: GuessLetter[];
  clue: string;
}

/**
 * Clue and guess line for a guess that's already been submitted.
 */
function Guess(props: GuessProps) {
  return (
    <div className="guess">
      <div className="clue">
        <div className="guideline"></div>
        <LightbulbIcon iconTitle="lightbulb" />
        <div className="copy">{props.clue}</div>
      </div>
      <div className="line">
        {props.letters.map((item: GuessLetter) => (
          <Tile letter={item.letter} status={getTileStatusClass(item.hint)} />
        ))}
      </div>
    </div>
  );
}

export default Guess;
