import React, { useState } from 'react';

import { useStore } from 'store/store';
import Hamburger from 'components/hamburger/Hamburger';
import Heading from 'components/heading/Heading';
import Logo from 'components/logo/Logo';
import Menu from 'components/menu/Menu';

import './Header.scss';

/**
 * Contains the app header area and its associated functionality.
 */
function Header() {
  const dispatch = useStore()[1];
  const [menuVisible, setMenuVisible] = useState(false);

  /**
   * Shows the instructions screen. We can be reasonably sure that if this
   * function is executed, the screen is not currently open, and toggling will simply
   * open it.
   */
  function showInstructions() {
    dispatch('TOGGLE_INSTRUCTIONS');
  }

  /**
   * Shows the stats screen. We can be reasonably sure that if this
   * function is executed, the screen is not currently open, and toggling will simply
   * open it.
   */
  function showStats() {
    dispatch('TOGGLE_STATS');
  }

  /**
   * Enables or disables high contrast mode.
   */
  function toggleContrast() {
    dispatch('TOGGLE_HIGH_CONTRAST');
  }

  /**
   * Opens or closes the menu screen.
   */
  function toggleMenu() {
    setMenuVisible(!menuVisible);
    dispatch('HIDE_STATS');
    dispatch('HIDE_INSTRUCTIONS');
  }

  return (
    <div className="header">
      <Logo reverse={menuVisible} />
      <Heading />
      <Hamburger toggleMenu={toggleMenu} reverse={menuVisible} />
      <Menu
        showInstructions={showInstructions}
        showStats={showStats}
        toggleContrast={toggleContrast}
        visible={menuVisible}
      />
    </div>
  );
}

export default Header;
