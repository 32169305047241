import React from 'react';

import { getTileStatusClass } from 'utility';
import { useStore } from 'store/store';
import './Key.scss';

interface KeyProps {
  onClick: (letter: string) => void;
  value: string;
}

/**
 * A key in a keyboard! It's whatever letter the prop is!
 * When clicked, it adds its letter to the active guess.
 */
function Key(props: KeyProps) {
  const state = useStore()[0];

  return (
    <div
      className={`key ${getTileStatusClass(state.hints[props.value])}`}
      onClick={() => {
        props.onClick(props.value);
      }}
    >
      {props.value}
    </div>
  );
}

export default Key;
