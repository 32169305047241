export const FETCHING = 0;
export const SUCCESS = 1;
export const FAILURE = 2;

export const GAME_LIVE = 3 as const;
export const GAME_WIN = 4 as const;
export const GAME_FAIL = 5 as const;

export const INCORRECT = -1 as const;
export const UNGUESSED = 0 as const;
export const PRESENT = 1 as const;
export const CORRECT = 2 as const;

export interface GuessLetter {
  letter: string;
  hint: typeof INCORRECT | typeof UNGUESSED | typeof PRESENT | typeof CORRECT;
}
